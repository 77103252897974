import axios from 'axios';
import Cookies from 'js-cookie';
import { message } from 'antd';

export const fetchWithAuth = async (method, url, data = null) => {
  const token = Cookies.get('access_token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response;
    switch (method) {
      case 'GET':
        response = await axios.get(url, config);
        break;
      case 'POST':
        response = await axios.post(url, data, config);
        break;
      case 'PUT':
        response = await axios.put(url, data, config);
        break;
      case 'DELETE':
        response = await axios.delete(url, config);
        break;
      default:
        throw new Error('Invalid method');
    }
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      message.error('Unauthorized. Please log in.');
    } else {
      message.error('An error occurred while fetching data.');
    }
    throw error;
  }
};
