import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Skeleton, Modal, Form, message, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import AddCameraForm from '../../components/forms/AddCameraForm';
import CameraPreview from '../../components/videoplayer/CameraPreview';

function CamerasPage() {
  const dispatch = useDispatch();
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addingCamera, setAddingCamera] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCamera, setEditingCamera] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const token = Cookies.get('access_token');
        const response = await axios.get('https://backend.bizdin.ai/api/core/cameras', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const camerasWithOriginalHlsUrl = response.data.map((camera) => ({
          ...camera,
          rtspUrl: camera.rtsp_url,
          hlsUrl: camera.hls_url,
        }));

        setCameras(camerasWithOriginalHlsUrl);
      } catch (error) {
        if (error.message === 'Unauthorized') {
          message.error('Unauthorized. Please log in.');
        } else {
          message.error('An error occurred while fetching the cameras.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCameras();
  }, [dispatch]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCamera(null);
    form.resetFields();
  };

  const handleAddCamera = async (cameraData) => {
    setAddingCamera(true);
    try {
      const token = Cookies.get('access_token');
      const response = await axios.post(
        'https://backend.bizdin.ai/api/core/cameras',
        {
          rtsp_url: cameraData.rtspLink,
          name: cameraData.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 201) {
        message.success('Camera added successfully!');
        setIsModalVisible(false);

        const newCamera = {
          ...response.data,
          rtspUrl: response.data.rtsp_url,
          hlsUrl: response.data.hls_url,
        };

        setCameras((prevCameras) => [...prevCameras, newCamera]);
      } else {
        message.error('Failed to add camera.');
      }
    } catch (error) {
      message.error('An error occurred while adding the camera.');
    } finally {
      setAddingCamera(false);
    }
  };

  const handleUpdateCamera = async (cameraData) => {
    try {
      const token = Cookies.get('access_token');
      const response = await axios.put(
        `https://backend.bizdin.ai/api/core/cameras/${editingCamera.id}/`,
        {
          rtsp_url: cameraData.rtspLink,
          name: cameraData.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        message.success('Camera updated successfully!');
        setIsModalVisible(false);
        const updatedCamera = {
          ...response.data,
          rtspUrl: response.data.rtsp_url,
          hlsUrl: response.data.hls_url,
        };
        setCameras((prevCameras) =>
          prevCameras.map((camera) => (camera.id === editingCamera.id ? updatedCamera : camera)),
        );
        setEditingCamera(null);
      } else {
        message.error('Failed to update camera.');
      }
    } catch (error) {
      message.error('An error occurred while updating the camera.');
    }
  };

  const handleEditCamera = (camera) => {
    setEditingCamera(camera);
    setIsModalVisible(true);
    form.setFieldsValue({
      name: camera.name,
      rtspLink: camera.rtspUrl,
    });
  };

  const handleDeleteCamera = async (cameraId) => {
    try {
      const token = Cookies.get('access_token');
      await axios.delete(`https://backend.bizdin.ai/api/core/cameras/${cameraId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Camera deleted successfully!');
      setCameras((prevCameras) => prevCameras.filter((camera) => camera.id !== cameraId));
    } catch (error) {
      message.error('An error occurred while deleting the camera.');
    }
  };

  const actions = (camera) => {
    const menu = (
      <Menu>
        <Menu.Item key="edit" onClick={() => handleEditCamera(camera)}>
          Edit
        </Menu.Item>
        <Menu.Item key="delete" onClick={() => handleDeleteCamera(camera.id)}>
          Delete
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
      </Dropdown>
    );
  };

  if (loading) {
    return (
      <>
        <CardToolbox>
          <PageHeader
            className="ninjadash-page-header-main"
            backIcon={false}
            title="All cameras"
            buttons={[
              <Link to="#" className="btn-add_new">
                <UilPlus /> Add New Camera
              </Link>,
            ]}
          />
        </CardToolbox>
        <Main>
          <Skeleton active style={{ minHeight: '60vh' }} />
        </Main>
      </>
    );
  }

  return (
    <>
      <CardToolbox>
        <PageHeader
          className="ninjadash-page-header-main"
          backIcon={false}
          title="All cameras"
          buttons={[
            <Link to="#" className="btn-add_new" onClick={showModal}>
              <UilPlus /> Add New Camera
            </Link>,
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={25}>
          {cameras.map((camera) => {
            const { id } = camera;
            return (
              <Col key={id} xxl={6} lg={8} sm={24} xs={24} className="">
                <Cards headless className="hover:shadow-box transition-all duration-200 rounded-md !p-0">
                  <div className="flex flex-row items-center justify-between">
                    <h3>{camera.name}</h3>
                    <div>{actions(camera)}</div>
                  </div>
                  <Link to={`/admin/pages/cameras/${id}`} className="mt-5">
                    <CameraPreview className="w-full" cameraName={camera.name} hlsUrl={camera.hls_url} />
                  </Link>
                </Cards>
              </Col>
            );
          })}
        </Row>
      </Main>

      <Modal
        title={editingCamera ? 'Edit Camera' : 'Add New Camera'}
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        okText={editingCamera ? 'Update Camera' : 'Add Camera'}
        cancelText="Cancel"
        className="dark-modal"
        confirmLoading={addingCamera}
      >
        <AddCameraForm onSubmit={editingCamera ? handleUpdateCamera : handleAddCamera} form={form} />
      </Modal>
    </>
  );
}

export default CamerasPage;
