import React from 'react';
import UilClockEight from '@iconscout/react-unicons/icons/uil-clock-eight';
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilCamera from '@iconscout/react-unicons/icons/uil-camera';
import { Switch } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function PlansCard() {
  return (
    <>
      <div className="flex items-center justify-between bg-white border-[1px] border-[#EBECEE] rounded-[10px] p-6 sm:w-full w-1/3">
        <div className="flex flex-col ">
          <p className="text-sm font-bold !text-black">Сценарий - отправить уведомление на устройство</p>
          <div className="flex items-center">
            <UilClockEight />
            <UilArrowRight className="ml-2" />
            <UilCamera className="ml-2" />
          </div>
        </div>

        <Switch {...label} defaultChecked />
      </div>
    </>
  );
}

export default PlansCard;
