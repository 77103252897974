import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import PlansCard from '../../components/plans/plansCard';

function PlansPage() {
  // const PageRoutes = [
  //   {
  //     path: 'index',
  //     breadcrumbName: 'Dashboard',
  //   },
  //   {
  //     path: '',
  //     breadcrumbName: 'Plans Page',
  //   },
  // ];

  return (
    <>
      <CardToolbox>
        <PageHeader
          className="ninjadash-page-header-main"
          backIcon={false}
          title="All scenarios"
          subTitle={
            <>
              <span className="title-counter">18 scenarios</span>
              <AutoComplete
                // onSearch={handleSearch}
                // dataSource={filteredData}
                width="100%"
                placeholder="Search by Name"
                patterns
              />
            </>
          }
          buttons={[
            <Link to="/admin/pages/add-plan" className="btn-add_new">
              <UilPlus /> Add New Scenario
            </Link>,
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <h1 className="text-white">Plans:</h1>
            <PlansCard />
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default PlansPage;
