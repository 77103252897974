import axios from 'axios';
import Cookies from 'js-cookie';
import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;
const token = getItem('access_token');
const headersBase = {
  'Content-Type': 'application/json',
};

if (token) {
  headersBase.Authorization = `Bearer ${getItem('access_token')}`;
}
console.log(headersBase);
const authHeader = () => (token ? { Authorization: `Bearer ${token}` } : {});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: headersBase,
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  // const requestConfig = config;
  // const { headers } = config;
  // requestConfig.headers = { ...headers, ...(token && { Authorization: `Bearer ${getItem('access_token')}` }) };
  //
  // return requestConfig;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      Cookies.remove('access_token');
      Cookies.remove('logedIn');
      Cookies.remove('user');
      window.location.href = '/';
      return Promise.reject(new Error('Unauthorized'));
    }
    return Promise.reject(error);
  },
);
export { DataService };
